/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
    font-family: 'Poppins ExtraLight';
    src: url('../fonts/Poppins/Poppins-ExtraLight.eot');
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
        url('../fonts/Poppins/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraLight.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraLight.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraLight.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Medium';
    src: url('../fonts/Poppins/Poppins-Medium.eot');
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('../fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Medium.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Light Italic';
    src: url('../fonts/Poppins/Poppins-LightItalic.eot');
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
        url('../fonts/Poppins/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-LightItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-LightItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-LightItalic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins ExtraLight Italic';
    src: url('../fonts/Poppins/Poppins-ExtraLightItalic.eot');
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins Regular';
    src: url('../fonts/Poppins/Poppins-Regular.eot');
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Regular.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins SemiBold';
    src: url('../fonts/Poppins/Poppins-SemiBold.eot');
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('../fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-SemiBold.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Black';
    src: url('../fonts/Poppins/Poppins-Black.eot');
    src: local('Poppins Black'), local('Poppins-Black'),
        url('../fonts/Poppins/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Black.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Black.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Black.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins ExtraBold Italic';
    src: url('../fonts/Poppins/Poppins-ExtraBoldItalic.eot');
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins Medium Italic';
    src: url('../fonts/Poppins/Poppins-MediumItalic.eot');
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
        url('../fonts/Poppins/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-MediumItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-MediumItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-MediumItalic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins Light';
    src: url('../fonts/Poppins/Poppins-Light.eot');
    src: local('Poppins Light'), local('Poppins-Light'),
        url('../fonts/Poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Light.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Light.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Light.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Bold';
    src: url('../fonts/Poppins/Poppins-Bold.eot');
    src: local('Poppins Bold'), local('Poppins-Bold'),
        url('../fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Bold.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Thin';
    src: url('../fonts/Poppins/Poppins-Thin.eot');
    src: local('Poppins Thin'), local('Poppins-Thin'),
        url('../fonts/Poppins/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Thin.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Thin.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Thin.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Italic';
    src: url('../fonts/Poppins/Poppins-Italic.eot');
    src: local('Poppins Italic'), local('Poppins-Italic'),
        url('../fonts/Poppins/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Italic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Italic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Italic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins Thin Italic';
    src: url('../fonts/Poppins/Poppins-ThinItalic.eot');
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
        url('../fonts/Poppins/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ThinItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ThinItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ThinItalic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins Black Italic';
    src: url('../fonts/Poppins/Poppins-BlackItalic.eot');
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
        url('../fonts/Poppins/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-BlackItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-BlackItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-BlackItalic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins Bold Italic';
    src: url('../fonts/Poppins/Poppins-BoldItalic.eot');
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
        url('../fonts/Poppins/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-BoldItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-BoldItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-BoldItalic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins SemiBold Italic';
    src: url('../fonts/Poppins/Poppins-SemiBoldItalic.eot');
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins ExtraBold';
    src: url('../fonts/Poppins/Poppins-ExtraBold.eot');
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
        url('../fonts/Poppins/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-ExtraBold.otf') format('opentype'),
        url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}


/*---------------------------------------------*/
@font-face {
    font-family: 'Playfair Display Black';
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Black.eot');
    src: local('Playfair Display Black'), local('PlayfairDisplay-Black'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Black.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Black.woff') format('woff'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display Regular';
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.eot');
    src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff') format('woff'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display Bold';
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.eot');
    src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff') format('woff'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display Italic';
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Italic.eot');
    src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Italic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Italic.woff') format('woff'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Playfair Display Black Italic';
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.eot');
    src: local('Playfair Display Black Italic'), local('PlayfairDisplay-BlackItalic'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.woff') format('woff'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Playfair Display Bold Italic';
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.eot');
    src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.woff') format('woff'),
        url('../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

/*---------------------------------------------*/
@font-face {
    font-family: 'Montserrat ExtraLight';
    src: url('../fonts/Montserrat/Montserrat-ExtraLight.eot');
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Medium Italic';
    src: url('../fonts/Montserrat/Montserrat-MediumItalic.eot');
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
        url('../fonts/Montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat Italic';
    src: url('../fonts/Montserrat/Montserrat-Italic.eot');
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url('../fonts/Montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Italic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: url('../fonts/Montserrat/Montserrat-Regular.eot');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Thin Italic';
    src: url('../fonts/Montserrat/Montserrat-ThinItalic.eot');
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
        url('../fonts/Montserrat/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-ThinItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat Bold';
    src: url('../fonts/Montserrat/Montserrat-Bold.eot');
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('../fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Light';
    src: url('../fonts/Montserrat/Montserrat-Light.eot');
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('../fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Light.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Black';
    src: url('../fonts/Montserrat/Montserrat-Black.eot');
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url('../fonts/Montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Black.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url('../fonts/Montserrat/Montserrat-Medium.eot');
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('../fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat ExtraLight Italic';
    src: url('../fonts/Montserrat/Montserrat-ExtraLightItalic.eot');
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
        url('../fonts/Montserrat/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat Black Italic';
    src: url('../fonts/Montserrat/Montserrat-BlackItalic.eot');
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url('../fonts/Montserrat/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-BlackItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat ExtraBold Italic';
    src: url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.eot');
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
        url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat SemiBold Italic';
    src: url('../fonts/Montserrat/Montserrat-SemiBoldItalic.eot');
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
        url('../fonts/Montserrat/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat ExtraBold';
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.eot');
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Bold Italic';
    src: url('../fonts/Montserrat/Montserrat-BoldItalic.eot');
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url('../fonts/Montserrat/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat Light Italic';
    src: url('../fonts/Montserrat/Montserrat-LightItalic.eot');
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url('../fonts/Montserrat/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-LightItalic.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-LightItalic.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat SemiBold';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.eot');
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Thin';
    src: url('../fonts/Montserrat/Montserrat-Thin.eot');
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
        url('../fonts/Montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Thin.woff') format('woff'),
        url('../fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}


@font-face {
    font-family: 'Anton Regular';
    src: url('../fonts/Anton/Anton.eot');
    src: local('Anton Regular'), local('Anton Regular'),
        url('../fonts/Anton/Anton.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Anton/Anton.woff2') format('woff2'),
        url('../fonts/Anton/Anton.woff') format('woff'),
        url('../fonts/Anton/Anton.ttf') format('truetype'),
        url('../fonts/Anton/Anton.otf') format('opentype'),
        url('../fonts/Anton/Anton.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}

/* [data-page-version="dashboard"]  */
/* [data-page-version="admin"] body { */
[data-page-version="admin"] {
    margin: 0;
    font-family: 'Poppins Regular', sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: #1E2761;
    background-color: #fff;
    /* theme-red */
}

/* [data-page-version="login"] body { */
[data-page-version="login"] {
    margin: 0;
    font-family: 'Poppins Regular', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #1E2761;
    text-align: left;
    background-color: #fff;
    /* theme-red */
}


.login-row .title-div {
    position: relative;
}

.login-row .title-div .logo-corner {
    position: absolute;
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
}

.login-row .title-div .vertical-center {
    margin-top: 350px;
    margin-left: 100px;
    margin-right: 10px;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: right;
}



.login-row .title-div .vertical-center .main_head {
    color: #fff;
    font-family: 'Anton Regular', sans-serif;
    font-size: 42px;
    text-transform: uppercase;
}

.login-row .title-div .vertical-center .sub_main_head {
    color: black;
    font-family: 'Anton Regular', sans-serif;
    font-size: 40px;
    text-transform: uppercase;
}

.login-row .title-div .vertical-center .sub_main_content {
    color: grey;
    font-family: 'Poppins Regular', sans-serif;
    font-size: 24px;
}




.login-row .login-half-round {
    background-color: #f8e27d;
    overflow: hidden;
    text-align: center;

    &.vertical {
        width: auto;
        height: 690px;

        &.right {
            border-radius: 0 100% 100% 0 / 0 100% 100% 0;
        }

    }

}

.login-row .login-half-round .login-half-round-content {
    font-size: 58px;
    font-weight: 500;
    display: block;
    margin-top: 220px;
}

.login-row .login-half-round .login-half-round-content>a {
    text-align: left;
    display: inline-block;
    line-height: 52px;
    text-decoration: none;
    color: #F96167;
    font-weight: 700;
}



.login-row .login-small-round {
    background-color: #f8e27d;
    overflow: hidden;
    text-align: center;
    /* &.vertical { */
    width: 400px;
    height: 400px;

    /* &.right { */
    transform: translate(-40%, -40%);
    border-radius: 50%;

    /* }

    } */

}

.login-row .login-small-round .login-small-round-content {
    display: block;
    margin-top: 60%;
    margin-left: 30%;
}

.login-row .login-small-round .login-small-round-content>a {
    text-align: left;
    display: inline-block;
    line-height: 30px;
    text-decoration: none;
    color: #F96167;
    font-size: 32px;
    font-weight: 500;
}

.login-hr {
    float: none;
    border: 1px solid grey;
    opacity: 0.1;
}

.poppins-bold-family {
    font-family: 'Poppins Bold', sans-serif;
}

.login__submit {
    font-size: 14px;
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 26px;
    border: 1px solid #f8e27d;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: 500;
    align-items: center;
    width: auto;
    background-color: #f8e27d;
    color: #F96167;
    /* box-shadow: 0px 1px 1px #f8e27d; */
    cursor: pointer;
    transition: .2s;
    display: inline;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
    border-color: #F96167;
    color: #F96167;
    outline: none;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* Rounded menu */
/* .menu-link {
    height: 60px;
    width: 60px;
    background-color: #f8e27d;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
}

.menu-icon {
    font-size: 10px;
    font-weight: 500;
    padding-top: 15px;
    color: white;
    display: flex;
    justify-content: center;
}

.menu-text {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    color:  #F96167;
    display: flex;
    justify-content: center;
} */

/*end Rounded menu */

.menu-link-active {
    border: 3px solid black;
}

.profile-div {
    /* height: 120px;
    width: 120px;
    background-color: #f8e27d;
    border-radius: 50%; */
    /* display: inline-block; */
}

.navMenu {
    font-size: 1rem;
    line-height: 1.5;
    /* margin-top: 20px; */
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding: 8px 15px;
    border-radius: 25px;
    border: 2px solid #f8e27d;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: 400;
    align-items: center;
    width: auto;
    background-color: #f8e27d;
    -webkit-text-fill-color: #F96167;
    color: #F96167;
    /* box-shadow: 0px 1px 1px #f8e27d; */
    cursor: pointer;
    transition: .2s;
    display: inline;
}

.navMenu:active,
.navMenu-active,
.navMenu:focus,
.navMenu:hover {
    border-color: #F96167;
    /* border-bottom: 5px solid brown; */
    background-color: #fff;
    /* theme-red */
    /* color: #f8e27d; */
    color: #F96167;
    -webkit-text-fill-color: #F96167;
    outline: none;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.navMenu-dropdown {
    font-size: 12px;
    line-height: 3.5;
    /* margin-top: 20px; */
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding: 8px 15px;
    border-radius: 25px;
    border: 2px solid #f8e27d;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: 400;
    align-items: center;
    width: auto;
    background-color: #f8e27d;
    color: #F96167;
    /* box-shadow: 0px 1px 1px #f8e27d; */
    cursor: pointer;
    transition: .2s;
    display: inline;
}

.navMenu-dropdown:active,
.navMenu-dropdown-active,
.navMenu-dropdown:focus,
.navMenu-dropdown:hover {
    border-color: #F96167;
    /* border-bottom: 5px solid brown; */
    background-color: #fff;
    /* theme-red */
    color: #f8e27d;
    outline: none;
}


.dropdown-menu {
    background-color: #fff;
    /* theme-red */
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.view__btn {
    font-size: 16px;
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 26px;
    border: 1px solid #f8e27d;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: 500;
    align-items: center;
    width: auto;
    background-color: #f8e27d;
    color: #F96167;
    /* box-shadow: 0px 1px 1px #f8e27d; */
    cursor: pointer;
    transition: .2s;
    display: inline;
}

.view__btn:active,
.view__btn:focus,
.view__btn:hover {
    border-color: #F96167;
    color: #F96167;
    outline: none;
}



.normal__btn,
.submit__btn,
.clear__btn,
.preview__btn {
    font-size: 1rem;
    line-height: 1.5;
    /* margin-top: 20px; */
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding: 8px 15px;
    border-radius: 25px;
    border: 2px solid #f8e27d;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: 400;
    align-items: center;
    width: auto;
    background-color: #f8e27d;
    -webkit-text-fill-color: #F96167;
    color: #F96167;
    /* box-shadow: 0px 1px 1px #f8e27d; */
    cursor: pointer;
    transition: .2s;
    display: inline;
}

.normal__btn:active,
.normal__btn-active,
.normal__btn:focus,
.normal__btn:hover,
.preview__btn:active,
.preview__btn-active,
.preview__btn:focus,
.preview__btn:hover,
.submit__btn:active,
.submit__btn-active,
.submit__btn:focus,
.submit__btn:hover,
.clear__btn:active,
.clear__btn-active,
.clear__btn:focus,
.clear__btn:hover {
    border-color: #F96167;
    /* border-bottom: 5px solid brown; */
    background-color: #fff;
    /* theme-red */
    color: #f8e27d;
    -webkit-text-fill-color: #f8e27d;
    outline: none;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}


.tab__btn {
    font-size: 1rem;
    line-height: 1.5;
    /* margin-top: 20px; */
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding: 8px 15px;
    border-radius: 25px;
    border: 2px solid #f8e27d;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: 400;
    align-items: center;
    width: auto;
    background-color: #f8e27d;
    -webkit-text-fill-color: #F96167;
    color: #F96167;
    /* box-shadow: 0px 1px 1px #f8e27d; */
    cursor: pointer;
    transition: .2s;
    display: inline;
}

.tab__btn:active,
.tab__btn-active,
.tab__btn:focus,
.tab__btn:hover {
    border-color: #F96167;
    /* border-bottom: 5px solid brown; */
    background-color: #fff;
    /* theme-red */
    color: #F96167;
    -webkit-text-fill-color: #F96167;
    outline: none;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}



.logo-div {
    display: flex;
    /* width: 150px; */
    /* width: 100px; */
    margin-left: 15px;
    justify-content: space-between;
}

.bell-div {
    width: 200px;
    /* position: absolute; */
    /* align-items: end; */
}

.bell-dropdown {
    border: 1px solid #f8e27d;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    /* position: absolute; */
}

.bell-dropdown li {
    border-bottom: 1px solid #f8e27d;
    padding-bottom: 10px;
    padding-top: 10px;
    text-decoration: none;
}

.bell-dropdown li a {
    color: #1E2761;
    text-decoration: none;
}

.bell-dropdown li a:hover {
    color: blue;
    text-decoration: none;
}


.profile-div .profile-logo {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: inline-block;
    position: relative;
}

/* borders */

.border-1-brown {
    border: 1px solid brown;
}

.border-2-brown {
    border: 2px solid brown;
}

.border-radius-10 {
    border-radius: 10px;
}

.border-radius-25 {
    border-radius: 25px;
}

.border-radius-50 {
    border-radius: 50px;
}

.border-radius-75 {
    border-radius: 75px;
}

.border-radius-100 {
    border-radius: 100px;
}

.border-radius-125 {
    border-radius: 125px;
}

.border-radius-150 {
    border-radius: 150px;
}


.content-div {
    border-radius: 25px;
    /* border: 1px solid #f8e27d; */
    padding: 15px;
}

.content-div label,
.content-div legend {
    color: #f8e27d;
}

.content-div fieldset {
    margin-bottom: 20px;
}

.content-div .breadcums_head {
    display: flex;
    justify-content: space-between;
}


.vertical-center {
    margin: 0;
    padding-right: 10px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-right: 6px solid #f8e27d;
}


/* Colors */

.alert-success,
.jewell-bg-color {
    background-color: #f8e27d;
    color: #F96167;
}

.jewell-color {
    color: #1E2761;
}


.bg-pink,
.alert-danger,
.bg-color1 {
    /* background-color: pink;
     */
    background-color: orange
}

.bg-color2 {
    background-color: #ffd700;
}

.bg-color3,
.ribbon-wrapper .active {
    background-color: #00ff7f;
}

.bg-color4 {
    background-color: #00ffff;
}

.bg-green,
.bg-color5 {
    background-color: #00ff00;
}

.bg-color6 {
    background-color: #fff68f;
}

.bg-white {
    background-color: #fff;
}

.bg-crimson-red {
    background-color: #990000;
}

.bg-cherry-red,
.ribbon-wrapper .deactive {
    background-color: #c24641;
}

.bg-blood-red {
    background-color: #660000;
}

.bg-deep-red {
    background-color: #800517;
}

.bg-red {
    background-color: red;
}

.bg-orange {
    background-color: orange;
}

.bg-fb-blue {
    background-color: #4267B2;
}

.bg-grey {
    background-color: grey;
}

/* color */

.fb-blue {
    color: #4267B2;
}

.light-coral {
    color: lightcoral;
}

.light-orange {
    color: #FFD580;

}

.black {
    color: black;
}

.white {
    color: white;
}

.brown {
    /* color: #F96167; */
    color: brown;
}

.pink {
    color: pink;
}

.theme-yellow {
    color: #f8e27d;
}

.theme-red {
    color: #1E2761;
}

.theme-brown {
    color: #F96167;
}

.theme-bg-brown {
    background-color: #fff;
    /* theme-red */
}

.theme-bodrer-brown {
    border: #F96167;
}

.grey {
    color: grey;
}

.light-green {
    color: #00ff7f;
}

.green {
    color: green;
}

.silver {
    color: #c0c0c0;
}

.light-blue {
    color: lightskyblue;
}

.red,
.error {
    color: red;
}

.light-red {
    /* color: #FFCCCB; */
    color: #FF7F7F;
}

input:read-only {
    background-color: #fff;
    /* theme-red */
}


/* @media only screen and (max-width: 1300px) {
    body {
        background-image: url("../images/yellow-bg-1300-1000.png");
    }
}

@media only screen and (max-width: 991px) {
    body {
        background-image: url("../images/yellow-bg-991-1000.png");
    }
}

@media only screen and (max-width: 767px) {
    body {
        background-image: url("../images/yellow-bg-767-1000.png");
    }
}

@media only screen and (max-width: 720px) {
    body {
        background-image: url("../images/yellow-bg-720-1000.png");
        background-size: 100% 100%;
    }
}

@media only screen and (max-width: 653px) {
    body {
        background-image: url("../images/yellow-bg-600-1000.png");
        background-size: 100% 100%;
    }
}

@media only screen and (max-width: 500px) {
    body {
        background-image: url("../images/yellow-bg-500-1000.png");
        background-size: 100% 100%;
    }
}

@media only screen and (max-width: 396px) {
    body {
        background-image: url("../images/yellow-bg-400-1000.png");
        background-size: 100% 100%;
    }
} */




/* start Preloader */
/* Editable */
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /* background-color: #fff; */
    z-index: 1;
}

.sk-three-bounce {
    margin: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 36px;
    /* background-color: white; */
}

.sk-three-bounce .sk-child {
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    /* background-color: #2f4cdd; */
    /* background-color: #c28000; */
    background-color: #f8e27d;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}


/* Pagination */
/* Editable */
#pagination {}

.pg-three-bounce {
    text-align: center;
}

.pg-three-bounce>i,
.pointer {
    cursor: pointer;
}

.pg-three-bounce .pg-child {
    padding: 0px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #f8e27d;
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
}

.pg-three-bounce a.pg-child {
    text-decoration: none;
    color: #F96167;
}

.notextDecor {
    text-decoration: none;
}

.pg-three-bounce .pg-child.bg-white {
    cursor: unset;
}

.pg-three-bounce .pg-child.bg-clicked {
    background-color: #fff;
    /* theme-red */
    color: #f8e27d;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    /* border-color: white; */
    /* border: 1px solid; */
    cursor: unset;
}

.pg-three-bounce .pg-child:hover {
    background-color: #fff;
    /* theme-red */
}

.pg-three-bounce .pg-child>span {
    line-height: 30px;
}


.pg-three-bounce button.pg-child {
    border: none;
    background-color: #fff;
    /* theme-red */
    color: white;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.pg-three-bounce button.pg-child:disabled {
    background-color: #fff;
    /* theme-red */
    cursor: unset;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
}

.pg-three-bounce .pg-child>i {
    line-height: 30px;
    text-align: center;
    border: none;
    padding-left: 10px;
}


/* .pg-three-bounce .pg-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.pg-three-bounce .pg-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
} */

.form-control {
    /* color: white; */
    color: black;
    background-color: #fff;
    /* theme-red */
    border-color: #f8e27d;
    padding: 12px 20px;
    border-radius: 15px;
    font-size: 18px;
}


.form-control::placeholder {
    color: grey;
    opacity: 0.3;
    /* Firefox */
}

.form-control:focus {
    color: white;
    background-color: #fff;
    /* theme-red */
    /* opacity: 0.8; */
    /* Firefox */
}

.form-control::file-selector-button,
.form-control[type=file] {
    background-color: #fff;
    /* theme-red */
    color: #f8e27d;
}

.input::placeholder {
    color: grey;
    /* opacity: 0.2; */
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: grey;
}



.css-13cymwt-control {
    background-color: #F9E795 !important;
    /* theme-red */
    color: #fff !important;
    border-color: #7A2048 !important;
    /* padding: 10px 15px; */
    border-radius: 26px !important;
}

.css-t3ipsp-control,
.css-1nmdiq5-menu {
    background-color: #F96167 !important;
    color: #fff !important;
}

.css-qbdosj-Input,
.css-qbdosj-Input input {
    color: #fff !important;
}

.css-1dimb5e-singleValue {
    color: #fff !important;
    width: 100%;
}

.css-1hb7zxy-IndicatorsContainer {
    /* width: 10px; */
    color: #fff;
}

.css-1u9des2-indicatorSeparator,
.css-tj5bde-Svg {
    color: #fff;
}

.css-1xc3v61-indicatorContainer {
    padding: 5px;
}

@-webkit-keyframes sk-three-bounce {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-three-bounce {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* End Preloader */

@keyframes righttobottom {
    0% {
        top: 10%;
    }
}


@keyframes righttoleft {
    0% {
        left: 50%;
        right: 50%;
        bottom: 10%;
    }
}

.floatRight {
    float: right;
}

/* End StatusBar */


.tabDiv {
    /* margin-left: 10px;
    margin-top: 10px; */
}

.tabLink:hover {
    border: 2px solid brown;
    background-color: #f8e27d;
    font-size: 14px;
}



.hide {
    display: none;
}

/* React Validate style */
.react-inputs-validation__error,
.error {
    color: red;
    text-transform: capitalize;
    font-weight: 400;
}

input[type=radio] {
    display: none;
}


/* Datetime Style */
.noborder,
.react-datetime-picker__wrapper {
    border: none !important;
}


.numberCircle {
    border-radius: 50%;
    width: 10px;
    height: 0px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 2px;
    position: relative;

    background: #f8e27d;
    border: 1px solid #F96167;
    text-align: center;
}


/* imageFile */

.img-container {
    position: relative;
    /* -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    border-radius: 10px; */
}

/* .img-container img {
    padding: 10px;
} */

.img-drop-container {
    /* position: relative; */
    position: relative;
    display: flex;
    /* gap: 10px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    /* padding: 20px; */
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;

}

.img-drop-container:hover {
    background: #eee;
    border-color: #111;
}

.img-drop-container:hover .drop-title {
    color: #222;
}

.img-drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
}


.empty-container {
    width: 1900px;
    height: 900px;
    border: 1px solid grey;
    border-radius: 8px;
}

.empty-img {
    width: 110px;
    height: 110px;
}

.img-file {
    width: 110px;
    height: 110px;
    border-radius: 8px;
}

.default-banner-file {
    width: 150px;
    height: 75px;
    border-radius: 8px;
}

.default-about-file {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}

.medium-file {
    width: 300px;
    height: 300px;
}

.banner-file {
    width: inherit;
    height: 250px;
}

.img-container .delete-btn {
    position: absolute;
    top: 100px;
    left: 102px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 8px;
    padding: 4px 8px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.img-container .banner-delete-btn {
    position: absolute;
    top: 235px;
    left: 90%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 8px;
    padding: 4px 8px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.img-container .feature-delete-btn {
    position: absolute;
    top: 290px;
    left: 290px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 8px;
    padding: 4px 8px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.img-container .preview-btn {
    position: absolute;
    top: 90px;
    left: 12px;
    color: #f8e27d;
    font-size: 20px;
    text-align: center;
}

.img-container .select-btn {
    position: absolute;
    top: 50px;
    left: 140px;
    color: white;
    font-size: 20px;
    text-align: center;
}

.img-container .select-btn:hover {
    color: green;
}

.img-container .feature-btn {
    position: absolute;
    top: 230px;
    left: 15px;
    color: #f8e27d;
    font-size: 20px;
    text-align: center;
}

.img-container .banner-btn {
    position: absolute;
    top: 230px;
    left: 12px;
    color: #f8e27d;
    font-size: 20px;
    text-align: center;
}

.img-container .view-btn {
    position: absolute;
    top: 90px;
    left: 12px;
    color: #f8e27d;
    font-size: 20px;
    text-align: center;
}



.img-container .worker-preview-btn {
    position: absolute;
    top: 55px;
    left: 12px;
    color: #f8e27d;
    font-size: 15px;
    text-align: center;
}

.img-container .delete-btn:hover {
    background-color: black;
}


select-btn .created_at {
    color: silver;
    font-size: 10px;
    font-weight: 400;
}

/* ribbon */
.ribbon-wrapper {
    /* width: 80px; */
    width: auto;
    height: 35px;
    overflow: hidden;
    position: absolute;
    bottom: -3px;
    left: -0px;
}

.ribbon-green {
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    text-align: center;
    /* text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px; */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    /* position: relative; */
    padding: 7px 7px;
    left: 3px;
    top: 56px;
    /* width: 100px; */
    border-radius: 5px;
    /* background-color: #8dc735; */
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(#8dc735), to(#649a12));
    background-image: -webkit-linear-gradient(top, #8dc735, #649a12);
    background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45); */
    color: #fff;
    /* -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
}



/* Font size Custom */
.fs-20 {
    font-size: 20px;
}

.fs-18 {
    font-size: 18px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}

.fs-10 {
    font-size: 10px;
}

.fs-8 {
    font-size: 8px;
}

.fs-6 {
    font-size: 6px;
}


/* width */

.wd-25 {
    width: 25px;
}

.wd-30 {
    width: 30px;
}

.wd-300 {
    width: 400px;
}

.maxw-60 {
    max-width: 60%;
}

.maxw-40 {
    max-width: 40%;
}

/* margins */
.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-125 {
    margin-top: 125px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-180 {
    margin-top: 180px;
}

.mt-200 {
    margin-top: 200px;
}

.mt-230 {
    margin-top: 230px;
}

.mt-240 {
    margin-top: 240px;
}

.mt-250 {
    margin-top: 250px;
}

.mt-300 {
    margin-top: 300px;
}

.mt-400 {
    margin-top: 400px;
}

.mt-450 {
    margin-top: 450px;
}

.mt-500 {
    margin-top: 500px;
}

.ml-350 {
    margin-left: 350px;
}

/* Card */
.card {
    width: 25rem;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    border-radius: 10px;
    background-color: #fff;
    /* theme-red */
    /* color:  #F96167; */
    color: #1E2761;
    /* color: grey; */
    font-weight: 600;
}

.card .card-body {
    padding: 5px;
}

.card .card-body .card-image {
    padding: 10px;
}

.card .card-body .card-image .card-profile-image {
    width: 100px;
    height: 100px;
}

.card .card-body .card-content {
    margin: 8px;
    width: 100%;
}



.card .card-body .card-content a i:hover {
    color: blue;
}

.worker-profile-image {
    width: 60px;
    height: 60px;
}

.view-profile-image {
    /* background; */
    width: 100px;
    height: 100px;
}

.gender-profile-image {
    width: 50px;
    height: 50px;
}



.login-card-header {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    color: #f8e27d;
}


.fa-class {
    width: 25px;
    text-align: center;
    font-size: 16px;
    padding-left: 5px;
}


.badge-wrapper {
    position: relative;
}

.badge {
    position: absolute;
    top: 0;
    right: -3px;
    display: inline-block;
    width: 5px;
    height: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #1E2761;
    /* border-radius: 50%;
    background-color: #ddd; */
}

.typeCss {
    animation: type 4s steps(60, end);
}

@keyframes type {
    from {
        width: 0;
    }
}

.modal .modal-dialog .modal-content {
    background-color: #fff;
    color: #1E2761
}

a.disabled {
    pointer-events: none;
    cursor: default;
}


/* mobile */
@media (max-width: 992px) {

    [data-page-version="admin"] .container-div {
        border-radius: 25px;
        padding: 20px;
        padding-top: 10px;
    }

    .form-head {
        padding-top: 5px;
    }

    .d-block-mob {
        display: block;
    }

    .content-div .breadcums_head {
        display: block;
    }


    .card .card-body .card-content {
        max-width: 180px;
    }

    .card .card-body .card-content .card-title {
        display: flex;
        justify-content: space-between;
        /* max-width: fit-content; */
    }

    .card .card-body .card-content .card-title .card-action {
        width: 30%;
        margin-left: 5px;
        min-width: 80px;
    }

    .card .card-body .card-content .worker-customer-div {
        display: block;
    }


    .css-1xc3v61-indicatorContainer {
        padding-left: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-right: 5px !important;
    }


    .css-1fdsijx-ValueContainer {
        width: 60px !important;
    }

    .tab-navbar-nav {
        padding-top: 10px;
        /* width: 30%; */
        display: flex;
        justify-content: center;
        flex-direction: unset;
    }

    .tab-navbar-nav .tab__btn {
        font-size: 10px;
        line-height: 1.0;
        /* margin-top: 20px; */
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        padding: 8px 15px;
        border-radius: 25px;
        border: 2px solid #f8e27d;
        /* text-transform: uppercase; */
        text-decoration: none;
        font-weight: 400;
        align-items: center;
        width: auto;
        background-color: #f8e27d;
        -webkit-text-fill-color: #F96167;
        color: #F96167;
        /* box-shadow: 0px 1px 1px #f8e27d; */
        cursor: pointer;
        transition: .2s;
        display: inline;
    }

    .tab-navbar-nav .tab__btn:active,
    .tab-navbar-nav .tab__btn-active,
    .tab-navbar-nav .tab__btn:focus,
    .tab-navbar-nav .tab__btn:hover {
        border-color: #F96167;
        /* border-bottom: 5px solid brown; */
        background-color: #fff;
        /* theme-red */
        color: #F96167;
        -webkit-text-fill-color: #F96167;
        outline: none;
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    }

    .tab-navbar-nav .nav-item {
        /* padding: 10px; */
    }

    .login-form-card .modal {
        /* position: absolute; */
    }

    .preview_btn_div {
        display: flex;
        justify-content: end;
    }



    .preview__btn {
        font-size: 9px;
        line-height: 0.8;
        margin-top: 20px;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        padding: 6px 9px;
        border-radius: 25px;
        border: 2px solid #f8e27d;
        /* text-transform: uppercase; */
        text-decoration: none;
        font-weight: 400;
        align-items: center;
        width: auto;
        background-color: #f8e27d;
        -webkit-text-fill-color: #F96167;
        color: #F96167;
        /* box-shadow: 0px 1px 1px #f8e27d; */
        cursor: pointer;
        transition: .2s;
        display: inline;
    }

    .preview__btn:active,
    .preview__btn-active,
    .preview__btn:focus,
    .preview__btn:hover {
        border-color: #F96167;
        /* border-bottom: 5px solid brown; */
        background-color: #fff;
        /* theme-red */
        color: #f8e27d;
        -webkit-text-fill-color: #f8e27d;
        outline: none;
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    }

    .img-container .banner-file {
        width: 100%;
        height: 250px;
    }

    .img-container .medium-file {
        width: 100%;
        height: 250px;
    }

    .medium-file {
        width: inherit;
        height: 300px;
    }

    .img-container-row {
        padding: 10px;
    }

    .img-modal-head {
        /* padding: 20px; */
        position: absolute;
        /* overflow: hidden; */
        z-index: 1;
        /* width: 350px; */
        margin-right: 10%;

        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        border-radius: 10px;
        background-color: #fff;
        /* theme-red */
    }

    .img-container .default-banner-file {
        width: 120px;
        height: 60px;
        border-radius: 6px;
    }

    .img-container .banner-delete-btn,
    .img-container .feature-delete-btn {
        position: absolute;
        top: 235px;
        left: 85%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        background-color: #555;
        color: white;
        font-size: 8px;
        padding: 4px 8px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
    }

    .footer {
        width: 100%;
        color: white;
        text-align: center;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        bottom: 0;
        margin-bottom: 5px;
        position: relative;
    }

    /* [data-page-version="admin"] .footer-navbar {
        display: flex;
        justify-content: space-around;
        padding: 10px;
        background-color: #F9E795;
    } */

    /* .footer .logo-div-web {
        display: flex;
        justify-content: space-around;
    } */

    .footer .logo-div-web .bell-div {
        /* position: fixed;
        text-align: center;
        z-index: 1000; */
        /* padding-bottom: 40px; */
        /* padding-top: 20px; */
    }

    .footer .logo-div-web .profile-logo-part {
        /* position: fixed;
        text-align: center;
        z-index: 1000; */
        /* margin-bottom: 40px; */
        /* position: fixed; */
    }

    .submit__btn {
        margin-top: 20px;
    }

    .login-row .title-div {
        height: auto;
    }

    .register-page .login-row .title-div {
        display: none;
    }

    .login-row .title-div .logo-corner {
        margin-top: 20px;
        margin-left: 0px;
    }

    .login-row .title-div .logo-corner img {
        width: 250px;
        height: 250px;
    }

    .login-row .title-div .vertical-center {
        margin-top: 450px;
        margin-left: 120px;
        /* position: relative; */
    }

    .login-row .small-corner-logo img {
        width: 150px;
        height: 150px;
    }


    [data-page-version="admin"] .small-corner-logo img {
        width: 90px;
        height: 80px;
    }

    [data-page-version="admin"] .navbar {
        padding-top: 5px;
        /* padding-bottom: 2px; */
        padding-right: 10px;
        padding-left: 0px;
        /* background-color: #F9E795; */
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
        border-radius: 0px;


    }




    [data-page-version="admin"] .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }

    [data-page-version="admin"] .logo-div-mob {
        display: none;
    }

    [data-page-version="admin"] .bell-div {
        width: 30px;
        /* position: absolute; */
        /* align-items: end; */
    }

    [data-page-version="admin"] .bell-div ul {
        padding-left: 0px;
    }

    .tox .tox-editor-header {
        z-index: 1;
    }

    .navbar-collapse .menu-navbar-nav {
        position: absolute;
        overflow: hidden;
        z-index: 2;
        width: 160px;
        height: auto;
        left: 55%;
        /* margin-top: -50px; */

        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        border-radius: 10px;
        background-color: #fff;
        /* theme-red */
    }

    .navbar-collapse .menu-navbar-nav .nav-item .navMenu {
        display: block;
        margin-bottom: 10px;
        text-align: center;
        width: 80%;
    }


    .navbar-collapse .menu-navbar-nav .dropdown .navMenu {
        margin-left: 5%;
    }

    .navbar-collapse .menu-navbar-nav .dropdown .dropdown-menu {
        margin-left: 10px;
        margin-bottom: 10px;
        text-align: center;
        width: 30%;
    }

    .login-row .login-form-card {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        margin-bottom: 30px;
        width: 80%;
    }

    /* login card */
    .login-row .login-form-card .login-card {
        background-color: #fff;
        /* theme-red */
        width: auto;
        padding: 10px;
    }

    .login-card-header {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #f8e27d;
    }

    .view-profile-image {
        /* background; */
        width: 60px;
        height: 60px;
    }

    .img-container .view-btn {
        position: absolute;
        top: 50px;
        left: 8px;
        color: #f8e27d;
        font-size: 14px;
        text-align: center;
    }

    .slick-list {
        padding-bottom: 40% !important;
    }

    .register-page .slick-list {
        padding-bottom: 0% !important;
    }

    .website-page .slick-list {
        padding-bottom: 0% !important;
    }

    /* .website-page .slick-initialized .slick-slide {
        width: 320px !important;
        margin-right: 10px;
        display: block;
    } */


    @media (max-height: 800px) {
        .login-row .title-div .logo-corner {
            margin-top: 10px;
            margin-left: 0px;
        }

        .login-row .title-div .logo-corner img {
            width: 150px;
            height: 150px;
        }

        .login-row .title-div .vertical-center {
            margin-top: 350px;
            margin-left: 100px;
            /* position: relative; */
        }

        .login-row .small-corner-logo img {
            width: 100px;
            height: 100px;
        }

        .slick-list {
            padding-bottom: 0% !important;
        }

        .register-page .small-corner-logo img {
            width: 80px;
            height: 80px;
        }

        .register-page .slick-list {
            height: 85% !important;
        }


    }

    @media (max-height: 680px) {

        .login-row .title-div .vertical-center {
            margin-top: 300px;
            margin-left: 100px;
            /* position: relative; */
        }

        .register-page .login-form-card {
            margin-top: 5px;
        }

        .login-row .small-corner-logo img {
            width: 100px;
            height: 100px;
        }

        .register-page .small-corner-logo img {
            width: 50px;
            height: 50px;
        }

        .login-row .title-div .vertical-center .main_head {
            font-size: 36px;
        }

        .login-row .title-div .vertical-center .sub_main_head {
            font-size: 34px;
        }

        .login-row .title-div .vertical-center .sub_main_content {
            font-size: 20px;
        }

        .slick-list {
            height: 90% !important;
        }

        .register-page .slick-list {
            height: 85% !important;
        }

        .website-page .slick-list {
            height: 95% !important;
        }

        .register-page .form-control {
            font-size: 12px;
        }
    }

    @media (max-height: 650px) {


        .slick-list {
            height: 85% !important;
        }

        .register-page .slick-list {
            height: 85% !important;
        }

        .register-page .small-corner-logo img {
            width: 50px;
            height: 50px;
        }

        .login-row .login-form-card {
            margin-top: 0px;
        }


    }

    .boxing {
        position: relative;
    }

    .mob-text-center {
        text-align: center;
    }

    .mob-main_head {
        margin: 0;
        text-align: center;
    }

    .mob-main_subhead {
        margin: 0;
        text-align: center;
    }

    .mob-m-t-1 {
        margin-top: 5px;
    }

    .mob-m-t-2 {
        margin-top: 10px;
    }

    .mob-m-t-4 {
        margin-top: 20px;
    }

    .mob-maxw-80 {
        max-width: 80%;
    }

    .mob-fs-8 {
        font-size: 8px !important;
    }
}


@media (max-width: 400px) {
    .card .card-body .card-content {
        max-width: 220px;
    }

    .modal-body .card .card-body .card-content {
        max-width: 190px;
    }

    .css-1fdsijx-ValueContainer {
        width: 40px !important;
    }

}

@media (max-width: 380px) {
    .card .card-body .card-content {
        max-width: 190px;
    }

    .modal-body .card .card-body .card-content {
        max-width: 180px;
    }

    .css-1fdsijx-ValueContainer {
        width: 60px !important;
    }

}

@media (max-width: 360px) {


    .css-1fdsijx-ValueContainer {
        width: 40px !important;
    }

}





/* Web Desktop */
@media (min-width: 992px) {

    [data-page-version="admin"] .navbar {
        /* background-color: #F9E795; */
    }

    .website-page .slick-slider .slick-list .col-sm-4 {
        flex: 0 0 auto;
        width: 90%;
    }

    .d-flex-web {
        display: flex;
    }


    .footer {
        width: 100%;
        color: white;
        text-align: center;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        bottom: 0;
        margin-bottom: 20px;
        position: fixed;
    }

    [data-page-version="admin"] .small-corner-logo img {
        width: 100px;
        height: 100px;
    }

    [data-page-version="admin"] .logo-div-web {
        display: none;
    }

    .card .card-body .card-content .card-title {
        display: flex;
        justify-content: space-between;
        /* max-width: fit-content; */
    }

    .card .card-body .card-content .card-title .card-action {
        width: 30%;
        margin-left: 5px;
    }

    .card .card-body .card-content .worker-customer-div {
        display: flex;
        justify-content: space-around;
    }

    /* start StatusBar */
    /* Editable */
    #statusloader {
        position: fixed;
        top: 2%;
        right: 0%;
        left: 50%;
        bottom: 0%;
        height: 80px;

        /* width: 700px;
    height: 80px;
    left: 50%;
    right: 0%;
    top: 8%;
    bottom: 0%;
    border-radius:10px;
    border: 1px solid; */

        /* background-color: #fff; */
        z-index: 1;
        animation: righttoleft 1s 1;
        animation-direction: normal;
    }

    #statusloader .alertCustom {
        /* color: #ffffff;
    background-color: #FF8080; */
        /* font-family: 'Source Sans Pro', sans-serif; */
        border-radius: 10em;
        border: 1px solid;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 700px;
    }

    /* Login Box */
    .boxing {
        top: 0px;
        left: 100px;
        position: relative;
    }

    .login-row .login-form-card {
        margin-top: 130px;
        margin-bottom: 30px;
        display: block;
        padding-left: 50px;
        width: auto;
        /* margin-left: 300px; */
    }

    .register-page .login-form-card {
        margin-top: 80px;
    }

    .login-row .title-div .logo-corner {
        margin-top: 70px;
        margin-left: 70px;
    }

    .login-row .small-corner-logo {
        display: none;
    }

    .login-row .title-div .logo-corner img {
        width: 300px;
        height: 300px;
    }

    .login-row .title-div .vertical-center {
        margin-top: 350px;
        margin-left: 300px;
    }



    /* login card */
    .login-row .login-form-card .login-card {
        padding: 20px;
    }

    /* login card */
    .login-card {
        background-color: #fff;
        /* theme-red */
        width: 25rem;
        padding: 20px;
    }

}



.box {
    display: flex;
    /* justify-content: space-evenly; */
}

.box-item {
    width: 150px;
    height: 150px;
    margin: 10px;
}

.box-image img {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    height: 70px;
}

.box-head {
    padding: 10px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}

.box-content {
    font-size: 12px;
    text-align: center;
}

.success-box {
    background: linear-gradient(to bottom right, #B0DB7D 40%, #99DBB4 100%);
}

.error-box {
    background: linear-gradient(to bottom right, #EF8D9C 40%, #99DBB4 100%);
}

.color1 {
    background-color: #FFD700;
}

.color2 {
    background-color: #9FE2BF;
}

.color3 {
    background-color: #FFA07A;
}

.color4 {
    background-color: #00FFFF;
}

.color5 {
    background-color: Pink;
}

.color6 {
    background-color: #DDA0DD;
}

.color7 {
    background-color: #7FFFD4;
}

.color8 {
    background-color: #DAF7A6;
}

.color9 {
    background-color: #FFDEAD;
}

.white-color {
    background-color: #FFF;
    color: #1E2761;
}


/* Admin web page */
.website-page .slick-dots {
    text-align: center;
    margin-bottom: 0px;
    margin-top: 20px;
}

.website-page .slick-dots li {
    display: inline;
    margin-left: 5px;
    margin-right: 5px;
}

.website-page .slick-dots li button:before {
    content: " ";
    font-size: 0;
}

.website-page .slick-dots li button {
    background-color: transparent;
    border: 4px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    width: 20px;
    color: #fff;
    content: " ";
    font-size: 0;

}

.website-page .slick-dots li.slick-active button {
    background-color: #fff;
}



/* Login page */

.login-page .slick-dots {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.login-page .slick-dots li {
    display: inline;
    margin-left: 5px;
    margin-right: 5px;
}

.login-page .slick-dots li button:before {
    content: " ";
    font-size: 0;
}

.login-page .slick-dots li button {
    background-color: transparent;
    border: 4px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    width: 20px;
    color: #fff;
    content: " ";
    font-size: 0;

}

.login-page .slick-dots li.slick-active button {
    background-color: #fff;
}


/* TOP SLIDE  */
.top-slide .slick-dots {
    padding-left: 0px;
    padding-top: 0px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #fff;
}

.top-slide .slick-dots li {
    display: inline;
    margin-left: 5px;
    margin-right: 5px;
}

.top-slide .slick-dots li button:before {
    content: " ";
    font-size: 0;
}

.top-slide .slick-dots li button {
    background-color: transparent;
    border: 3px solid #1E2761;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    line-height: 10px;
    width: 10px;
    color: #1E2761;
    content: " ";
    font-size: 0;
}

.top-slide .slick-dots li.slick-active button {
    background-color: #1E2761;
}